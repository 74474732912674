:root {
    --white: #FFFFFF;
    --grey-white: #F5F5F7;
    --grey-alt: #F1F1F1;
    --grey: #EEEEEE;
    --green-cyan: #43EFD0;
    --dark-blue-title: #2F3367;
    --dark-blue-text: #303468;
    --grey-black: #00000026;
}

body {
    margin: 0;
    background-color: var(--white);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

@font-face {
    font-family: 'Poppins';
    src: local("Poppins"),
    url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Poppins-Light';
    src: local("Poppins-Light"),
    url("./assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
    font-family: 'Poppins-Bold';
    src: local("Poppins-Bold"),
    url("./assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: local("Poppins-SemiBold"),
    url("./assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

* {
    font-family: Poppins, 'sans-serif';
}
