.bold {
    font-family: Poppins-Bold, "sans-serif";
    font-weight: 700;
}

.landing-container {
    height: calc(100vh - 56px - 24px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-container__header {
    font-size: 36px;
    line-height: 44px;
    color: var(--dark-blue-title);
    max-width: 588px;
    text-align: center;
    height: 20%;
    display: flex;
    align-items: center;
}

.actions-container {
    display: flex;
    gap: 20px;
    align-items: center;
    margin: 0 100px;
    height: 80%;
}

.action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--grey-white);
    padding: 48px 40px;
    max-width: 383px;
    border-radius: 20px;
    gap: 28px;
    height: 180px;
}

.action__header {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: var(--dark-blue-text);
    text-align: center;
    width: 287px;
    height: 50%;
}

.action__header__qr {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: var(--dark-blue-text);
    text-align: center;
    height: 50%;
}

.action__button-wrapper {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.action__qrCode-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
    width: 80%;
}

.action__loader-wrapper {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.loader {
    border: 8px solid #FFFFFF;
    border-top: 8px solid var(--green-cyan);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 600px) {
    .landing-container__header {
        font-size: 30px;
        line-height: 35px;
        margin: 0 30px;
    }

    .action {
        min-width: fit-content;
    }

    .actions-container {
        margin-top: 50px;
        overflow-x: scroll;
        width: 100%;
        height: 50%;
    }
}
