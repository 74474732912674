.validated-container {
    background-color: var(--grey-black);
    height: calc(100vh - 56px - 24px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.validated-container__section {
    max-width: 610px;
    width: 90%;
    height: 596px;
    background-color: var(--white);
    gap: 40px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px 40px;
}

.validated-container__section_header {
    color: var(--dark-blue-text);
    font-size: 18px;
    text-align: center;
    line-height: 28px;
    font-weight: 400;
    max-width: 461px;
    width: 90%;
}

.credential-container {
    max-width: 530px;
    width: 80%;
    height: 350px;
    background-color: var(--grey-white);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.credential-container__image-wrapper {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.credential-container__image-wrapper img {
    width: 179px;
    height: 179px;
}

.credential-container__contents {
    width: 60%;
    display: grid;
    grid-template-columns: repeat(5, calc(100% / 5));
    grid-template-rows: repeat(13, calc((304px) / 13));
    column-gap: 4px;
}

.credential-container__contents_type {
    grid-column: 1 / 5;
    grid-row: 3 / 5;
}

.credential-container__contents_name {
    grid-column: 1 / 7;
    grid-row: 5 / 7;
}

.credential-container__contents_surname {
    grid-column: 1 / 5;
    grid-row: 7 / 9;
}

.credential-container__contents_university {
    grid-column: 1 / 6;
    grid-row: 9 / 11;
}

.credential-container__contents_year {
    grid-column: 1 / 5;
    grid-row: 11 / 13;
}

.credential-container__contents_expiration {
    grid-column: 1 / 5;
    grid-row: 13 / 15;
}

.credential-container h3 {
    color: var(--dark-blue-text);
    font-weight: 400;
    font-size: 12px;
}

.credential-container h2 {
    font-family: 'Poppins-SemiBold', sans-serif;
    color: var(--dark-blue-text);
    font-size: 16px;
    overflow: scroll;
    word-break: break-all;
    /*text-overflow: ellipsis;*/
}

.credential-container__contents_expiration h2 {
    font-size: 14px;
}

.credential-container__contents_year h2 {
    font-size: 14px;
}

.credential-container__default-content-wrapper {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .validated-container__section {
        padding: 0;
        width: 100%;
    }

    .credential-container__image-wrapper {
        width: 30%;
    }

    .credential-container__image-wrapper img {
        width: 80px;
        height: 80px;
    }

    .credential-container {
        width: 95%;
    }

    .credential-container__contents {
        width: 70%;
    }

    .credential-container__contents h2 {
        font-size: 12px;
    }
}
