.welcome-section {
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.welcome-section__header {
    font-family: Poppins-Bold;
    color: var(--dark-blue-title);
    font-size: 36px;
    line-height: 44px;
    width: 75%;
    margin-bottom: 35px;
}

.welcome-section__credentials-section {
    width: 75%;
    height: 75%;
    max-height: 670px;
    border: var(--grey) solid 1px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.welcome-section__credentials-section > :not(:first-child) {
    border-top: var(--grey-alt) solid calc(1px / 2);
}

.welcome-section__credentials-section > :not(:last-child) {
    border-bottom: var(--grey-alt) solid calc(1px / 2);
}

.credentials-section__credential-container {
    height: 30%;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.credentials-section__credential-container_header {
    color: var(--dark-blue-text);
    font-size: 16px;
    margin-bottom: 35px;
}

.credentials-section__credential-container_separator {
    width: 75%;
    height: 10px;
    background-color: var(--grey-alt);
}

@media only screen and (max-width: 600px) {
    .welcome-section {
        width: 100%;
    }

    .credentials-section__credential-container {
        height: auto;
        padding: 15px 0;
        align-items: center;
    }

    .welcome-section__credentials-section {
        padding: 20px;
        height: auto;
    }
}
