.main-container {
    background-color: var(--white);
    display: flex;
    width: 100vw;
    height: 100vh;
}

.main-container_background-image {
    width: 50vw;
    height: 100%;
    object-fit: cover;
}

.main-container_camins-logo {
    position: absolute;
    left: calc(25% - 222px / 2);
    bottom: calc(50% - 157px / 2);
    width: 222px;
    height: 157px;
}

.main-container_entities-logo {
    position: absolute;
    bottom: 0;
    width: 601px;
    left: calc(25% - 601px / 2)
}

@media only screen and (max-width: 600px) {
    .main-container {
        height: auto;
        padding: 20px 0;
    }

    .main-container_background-image {
        display: none;
    }

    .main-container_camins-logo {
        display: none;
    }

    .main-container_entities-logo {
        display: none
    }
}
