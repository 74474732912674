.content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 56px - 24px);
}

.content-container__header-1 {
    text-align: center;
    font-family: 'Poppins-Bold', sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: var(--dark-blue-title);
    top: 150px;
    width: 75%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-container__header-2 {
    width: 384px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-blue-text);
    margin-bottom: 30px;
    height: 10%;
}

.inputs-container {
    max-width: 384px;
    height: 70%;
    width: 80%;
}

.inputs-container > select {
    width: 100%;
    margin-bottom: 30px;
}

.inputs-container__input {
    width: 86%;
    height: 64px;
    border-radius: 10px;
    border: none;
    background-color: var(--grey-white);
    margin-bottom: 15px;
    outline: none;
    font-size: 16px;
    padding: 0 7%;
    text-overflow: ellipsis;
}

.inputs-container__select-input::placeholder {
    color: #8B8FA8
}
