.custom-button {
    display: flex;
    background-color: var(--green-cyan);
    align-items: center;
    border: none;
    border-radius: 8px;
    min-width: 259px;
    max-width: 366px;
    height: 60px;
    padding: 18px;
    cursor: pointer;
}

.custom-button h2 {
    font-family: 'Poppins-SemiBold', sans-serif;
    color: var(--dark-blue-title);
    font-size: 18px;
    line-height: 24px;
}

@media only screen and (max-width: 600px) {
    .custom-button {
        min-width: auto;
    }

    .custom-button h2 {
        font-size: 15px;
    }
}
