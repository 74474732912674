.header-container {
    background-color: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 12px 120px 12px 120px;
    box-shadow: 0px 4px 60px 0px #00000014;
}

.header__camins-logo {
    height: 32px;
    width: 141px;
}

.header__logout-button {
    display: flex;
    background-color: var(--grey-white);
    width: 100px;
    height: 32px;
    align-items: center;
    justify-content: space-around;
    padding: 4px 18px 4px 16px;
    border: none;
    border-radius: 20px;
    gap: 4px;
    cursor: pointer;
}

.header__logout-button h1 {
    font-size: 20px;
    font-family: Poppins, 'sans-serif';
    font-weight: 500;
    color: var(--dark-blue-title);
}

.header__logout-span img {
    height: 24px;
    width: 24px;
}

@media only screen and (max-width: 600px) {
    .header-container {
        padding: 12px 20px;
    }
}
