.page-container {
    background-color: var(--grey-black);
    height: calc(100vh - 56px - 24px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-container__section {
    max-width: 541px;
    max-height: 572px;
    background-color: var(--white);
    gap: 40px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px 40px;
}

.page-container__section_image {
    width: 280px;
    height: 280px;
}

.page-container__section_header {
    color: var(--dark-blue-text);
    font-size: 18px;
    text-align: center;
    line-height: 28px;
    font-weight: 400;
    max-width: 461px;
    width: 75%;
    margin: 0;
}

@media only screen and (max-width: 600px) {
    .page-container__section {
        padding: 30px 0;
    }
}
